import { h } from 'preact';
import register from 'preact-custom-element';
import './styles/fonts.css';
import './styles/global-styles.css';
import Button from './components/Button/Button.jsx';
import SearchModal from './components/SearchModal/SearchModal.jsx';
import SearchHit from './components/SearchHit/SearchHit.jsx';

register(Button, 'test-button', ['label', 'url', 'onClick', 'disabled'], { shadow: true });
register(SearchModal, 'search-modal', ['isOpen', 'onClose'], { shadow: true });
register(SearchHit, 'search-hit', ['title', 'category', 'body', 'url', 'image'], { shadow: true });
