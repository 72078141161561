/**
 * Truncate text by the number of characters without cutting off a word.
 * @param {string} text - The text to be truncated.
 * @param {number} charLimit - The number of characters to keep.
 * @returns {string} - The truncated text.
 */
export const truncate = (text, charLimit) => {
  if (text.length <= charLimit) {
    return text;
  }

  let truncatedText = text.slice(0, charLimit);

  // Ensure we do not cut off a word
  if (text[charLimit] !== ' ' && text[charLimit - 1] !== ' ') {
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
    if (lastSpaceIndex !== -1) {
      truncatedText = truncatedText.slice(0, lastSpaceIndex);
    }
  }

  return truncatedText + '...';
};
