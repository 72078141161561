import { h, Component, render } from 'preact';
import fonts from '../../styles/fonts.css';
import globalStyles from '../../styles/global-styles.css';

class ShadowWrapper extends Component {
  constructor(props) {
    super(props);
    this.shadowRootRef = null;
    this.containerRef = null;
  }

  componentDidMount() {
    // Create the shadow root and attach it to the base div
    this.shadowRootRef = this.containerRef.attachShadow({ mode: 'open' });

    // Inject font styles inside the shadow DOM
    const fontElementStyles = document.createElement('style');
    fontElementStyles.textContent = fonts;
    this.shadowRootRef.appendChild(fontElementStyles);

    // Inject global styles inside the shadow DOM
    const globalElemementStyles = document.createElement('style');
    globalElemementStyles.textContent = globalStyles;
    this.shadowRootRef.appendChild(globalElemementStyles);

    // Inject component styles inside the shadow DOM
    const styleElement = document.createElement('style');
    styleElement.textContent = this.props.styles;
    this.shadowRootRef.appendChild(styleElement);

    // Create a wrapper div for content inside the shadow DOM
    const contentElement = document.createElement('div');
    this.shadowRootRef.appendChild(contentElement);

    // Render children directly into the shadow DOM using Preact's `render`
    render(this.props.children, contentElement);
  }

  render() {
    return <div ref={(el) => (this.containerRef = el)} />;
  }
}

export default ShadowWrapper;
