import { h } from 'preact';
import styles from './Button.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';

const Button = (props) => {
  let { onClick, label, url, disabled } = props;
  const buttonElement = onClick ? (
    <button onClick={onClick} className="button" disabled={disabled}>
      {label}
    </button>
  ) : (
    <a href={url} className="button" disabled={disabled}>
      {label}
    </a>
  );
  return <ShadowWrapper styles={styles}>{buttonElement}</ShadowWrapper>;
};

export default Button;
